import React from "react";

import PropTypes from "prop-types";

import styled from "styled-components";

import { colors } from "./../../constants/colors";

const Breadcrumbs = ({ siteUrl, breadcrumbEntries, shouldRenderStructuredData = true }) => {
    return (
        <BreadcrumbListWrapper>
            {
                <BreadcrumbOrderedList
                    itemScope={true}
                    itemType="http://schema.org/BreadcrumbList"
                    className="breadcrumbs-container"
                >
                    {breadcrumbEntries.map((breadcrumbEntry, idx) => {
                        return (
                            <BreadcrumbListItem
                                key={idx}
                                itemProp="itemListElement"
                                itemScope={true}
                                itemType="http://schema.org/ListItem"
                            >
                                <a
                                    href={`${siteUrl}${breadcrumbEntry.url}`}
                                    itemScope={true}
                                    itemType="https://schema.org/WebPage"
                                    itemProp="item"
                                    itemID={`${siteUrl}${breadcrumbEntry.url}`}
                                >
                                    <span itemProp="name">{breadcrumbEntry.description}</span>
                                </a>
                                <meta itemProp="position" content={String(idx + 1)} />
                            </BreadcrumbListItem>
                        );
                    })}
                </BreadcrumbOrderedList>
            }
        </BreadcrumbListWrapper>
    );
};
const BreadcrumbListWrapper = styled.div``;

// TODO: @Jakub please implement any design if/when we decide to show the breadcrumbs
const BreadcrumbOrderedList = styled.ol`
    background-color: #f6f8f7;
    margin: 0px;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 180px;
    padding-right: 60px;
    list-style: none;
`;

const BreadcrumbListItem = styled.li`
    display: inline;
    margin: 0px;
    font-size: 15px;

    &:before {
        padding: 8px;
        color: ${colors.primary};
        content: " ";
    }
`;

Breadcrumbs.propTypes = {
    breadcrumbEntries: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default Breadcrumbs;
