const breadcrumbPagesWhiteList = {
    index: {
        path: [
            {
                description: "Home",
                url: "/",
            },
        ],
    },
    kreditrechner: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Kreditrechner",
                url: "/dienstleistungen/kreditrechner/",
            },
        ],
    },
    kreditvergleich: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Kreditvergleich",
                url: "/dienstleistungen/kreditvergleich/",
            },
        ],
    },
    aboutUs: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Über uns",
                url: "/ueber-uns/",
            },
        ],
    },
    konditionencheck: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Konditionencheck",
                url: "/dienstleistungen/konditionencheck/",
            },
        ],
    },
    umschuldungKonditionencheck: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Umschuldung Konditionencheck",
                url: "/dienstleistungen/umschuldung-konditionencheck/",
            },
        ],
    },
    bauKreditrechner: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Baukreditrechner",
                url: "/dienstleistungen/baukredit-rechner/",
            },
        ],
    },
    immobilienfinanzierung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Immobilienfinanzierung",
                url: "/artikel/immobilienfinanzierung/",
            },
        ],
    },
    umschuldung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Umschuldung",
                url: "/dienstleistungen/umschuldung/",
            },
        ],
    },
    wohnkredit: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Mit miracl zur Traumimmobilie: Dein individueller Wohnkredit",
                url: "/artikel/wohnkredit/",
            },
        ],
    },
    wohnkreditRechner: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Wohnkreditrechner",
                url: "/artikel/wohnkredit-rechner/",
            },
        ],
    },
    baufinanzierungWichtigstenTipps: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Baufinanzierung Die Wichtigsten Tipps",
                url: "/bauen/baufinanzierung-die-wichtigsten-tipps/",
            },
        ],
    },
    hausfinanzierung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Alles zum Thema Hausfinanzierung",
                url: "/artikel/hausfinanzierung/",
            },
        ],
    },
    faq: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "FAQ",
                url: "/faq/",
            },
        ],
    },
    /* Artikel */
    kaufnebenkosten: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Kaufnebenkosten",
                url: "/artikel/kaufnebenkosten/",
            },
        ],
    },
    kaufvertrag: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Der letzte Schritt beim Immobilienkauf: Dein Kaufvertrag",
                url: "/artikel/kaufvertrag/",
            },
        ],
    },
    steuernImmobilienkauf: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Steuern beim Immobilienkauf",
                url: "/artikel/steuern-beim-immobilienkauf/",
            },
        ],
    },
    zinsen: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Zinsen",
                url: "/artikel/zinsen/",
            },
        ],
    },
    dibeo: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Dibeo",
                url: "/dibeo/",
            },
        ],
    },
    bazar: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "BAZAR",
                url: "/bazar/",
            },
        ],
    },
    immoscout: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "ImmoScout24",
                url: "/immoscout/",
            },
        ],
    },
    kredit: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Kredit in Österreich",
                url: "/artikel/kredit/",
            },
        ],
    },
    kreditarten: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Kreditarten",
                url: "/artikel/kreditarten/",
            },
        ],
    },
    hypothekendarlehen: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Hypothekendarlehen",
                url: "/artikel/hypothekendarlehen/",
            },
        ],
    },
    bonitaet: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Bonität und Bonitätsprüfung",
                url: "/artikel/bonitaet/",
            },
        ],
    },
    kreditTipps: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Kredit-Tipps",
                url: "/artikel/kredit-tipps/",
            },
        ],
    },
    kreditAbgelehnt: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Abgelehnter Kreditantrag",
                url: "/artikel/kredit-abgelehnt/",
            },
        ],
    },
    inflation: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Inflation",
                url: "/artikel/inflation/",
            },
        ],
    },
    kostenWohnung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was kostet eine Wohnung wirklich?",
                url: "/artikel/kosten-wohnung/",
            },
        ],
    },
    schulden: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Alles zum Thema Schulden",
                url: "/artikel/schulden/",
            },
        ],
    },
    hausbauKosten: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Die Kosten beim Hausbau",
                url: "/artikel/hausbau-kosten/",
            },
        ],
    },
    rechtePflichtenAlsZukunftigeHauseigentumerin: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was darf ich als Hauseintümer*in tun?",
                url: "/artikel/rechte-und-pflichten-als-zukunftige-hauseigentumerin/",
            },
        ],
    },
    onlineKreditrechner: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Online-Kreditrechner",
                url: "/artikel/online-kreditrechner/",
            },
        ],
    },
    umschuldungEinfachErklart: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Umschuldung",
                url: "/artikel/umschuldung-einfach-erklart/",
            },
        ],
    },
    hauskauf: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "5 Dinge, die du vor dem Hauskauf beachten solltest",
                url: "/artikel/hauskauf/",
            },
        ],
    },
    leitzins: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Leitzins kurz und bündig erklärt",
                url: "/artikel/leitzins/",
            },
        ],
    },
    tilgung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Alles rund um Tilgung und Tilgungsplan",
                url: "/artikel/tilgung/",
            },
        ],
    },
    anschlussfinanzierung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Anschlussfinanzierung",
                url: "/artikel/anschlussfinanzierung/",
            },
        ],
    },
    bauen: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Bauen – Ein Überblick",
                url: "/artikel/bauen/",
            },
        ],
    },
    baufinanzierung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Baufinanzierung – worauf muss ich achten?",
                url: "/artikel/baufinanzierung/",
            },
        ],
    },
    baufinanzierungsberater: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Baufinanzierungsberater",
                url: "/artikel/baufinanzierungsberater/",
            },
        ],
    },
    cryptoAlsEigenkapital: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Crypto und NFTs als Eigenkapital",
                url: "/artikel/crypto-als-eigenkapital/",
            },
        ],
    },
    bonitaetspruefung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Bonitätsprüfung",
                url: "/artikel/bonitaetspruefung/",
            },
        ],
    },
    eigentum: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Eigentum – Pro und Contra",
                url: "/artikel/eigentum/",
            },
        ],
    },
    finanzierungOhneEigenkapital: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Die Finanzierung ohne Eigenkapital",
                url: "/artikel/finanzierung-ohne-eigenkapital/",
            },
        ],
    },
    grundbuchKosten: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Das Grundbuch – Welche Kosten dich erwarten",
                url: "/artikel/grundbuch-kosten/",
            },
        ],
    },
    hauseigentuemer: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Deine Rechte und Pflichten als zukünftige Hauseigentümer*in",
                url: "/artikel/hauseigentuemer/",
            },
        ],
    },
    immobilienkreditRechner: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Immobilienkredit-Rechner – jetzt Kredit berechnen mit miracl.",
                url: "/artikel/immobilienkredit-rechner/",
            },
        ],
    },
    immobilienblase: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Immobilienblase",
                url: "/artikel/immobilienblase/",
            },
        ],
    },
    kreditSelbststaendige: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Kredit für Selbständige",
                url: "/artikel/kredit-selbststaendige/",
            },
        ],
    },
    kreditzinsenEntwicklung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Kreditzinsen – Aktuelle Situation und Entwicklung",
                url: "/artikel/kreditzinsen-entwicklung/",
            },
        ],
    },
    mietkauf: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Alles zum Thema Mietkauf",
                url: "/artikel/mietkauf/",
            },
        ],
    },
    miracl: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "miracl die Finanzierungsbranche revolutionieren wird",
                url: "/artikel/miracl/",
            },
        ],
    },
    negativzins: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Negativzins – Einfach erklärt.",
                url: "/artikel/negativzins/",
            },
        ],
    },
    sanierung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Sanierung",
                url: "/artikel/sanierung/",
            },
        ],
    },
    sparenImmobilienKauf: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Wie du beim Immobilienkauf sparen kannst",
                url: "/artikel/sparen-immobilien-kauf/",
            },
        ],
    },
    tilgunsanteilBerechnen: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Tilgungsanteil berechnen",
                url: "/artikel/tilgunsanteil-berechnen/",
            },
        ],
    },
    tinyHouse: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Tiny House",
                url: "/artikel/tiny-house/",
            },
        ],
    },
    vorsorgewohnung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Vorsorgewohnung – Vom Geldwert zum Sachwert",
                url: "/artikel/vorsorgewohnung/",
            },
        ],
    },
    wasKostetEinHausImBurgenland: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was kostet ein Haus im Burgenland?",
                url: "/artikel/was-kostet-ein-haus-im-burgenland/",
            },
        ],
    },
    wasKostetEinHausInDerSteiermark: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was kostet ein Haus in der Steiermark?",
                url: "/artikel/was-kostet-ein-haus-in-der-steiermark/",
            },
        ],
    },
    wasKostetEinHausInNiederoesterreich: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was kostet ein Haus in Niederösterreich?",
                url: "/artikel/was-kostet-ein-haus-in-niederoesterreich/",
            },
        ],
    },
    wasKostetEinHausInOberosterreich: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was kostet ein Haus in Oberösterreich?",
                url: "/artikel/was-kostet-ein-haus-in-oberosterreich/",
            },
        ],
    },
    wasKostetEinHausInOesterreich: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was kostet ein Haus in Österreich?",
                url: "/artikel/was-kostet-ein-haus-in-oesterreich/",
            },
        ],
    },
    wasKostetEinHausInSalzburg: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was kostet ein Haus in Salzburg?",
                url: "/artikel/was-kostet-ein-haus-in-salzburg/",
            },
        ],
    },
    wasKostetEinHausInTirol: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was kostet ein Haus in Tirol?",
                url: "/artikel/was-kostet-ein-haus-in-tirol/",
            },
        ],
    },
    wasKostetEinHausInVorarlberg: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was kostet ein Haus in Vorarlberg?",
                url: "/artikel/was-kostet-ein-haus-in-vorarlberg/",
            },
        ],
    },
    wasKostetEinHausInKarnten: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was kostet ein Haus in der Kärnten?",
                url: "/artikel/was-kostet-ein-haus-in-karnten/",
            },
        ],
    },
    wasKostetEineWohnungInOesterreich: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was kostet eine Wohnung in Österreich?",
                url: "/artikel/was-kostet-eine-wohnung-in-oesterreich/",
            },
        ],
    },
    wohnungsfinanzierung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Wohnungsfinanzierung in 10 Schritten",
                url: "/artikel/wohnungsfinanzierung/",
            },
        ],
    },
    wasKostetEineWohnungImBurgenland: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was kostet eine Wohnung im Burgenland?",
                url: "/artikel/was-kostet-eine-wohnung-im-burgenland/",
            },
        ],
    },
    wasKostetEineWohnungInDerSteiermark: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was kostet eine Wohnung in der Steiermark?",
                url: "/artikel/was-kostet-eine-wohnung-in-der-steiermark/",
            },
        ],
    },
    wasKostetEineWohnungInNiederoesterreich: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was kostet eine Wohnung in Niederösterreich?",
                url: "/artikel/was-kostet-eine-wohnung-in-niederoesterreich/",
            },
        ],
    },
    wohnbauforderung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Alles rund um die Wohnbauförderung",
                url: "/artikel/wohnbauforderung/",
            },
        ],
    },
    hypothekenkredit: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was ist ein Hypothekenkredit?",
                url: "/artikel/hypothekenkredit/",
            },
        ],
    },
    immobilieninvestitionen: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Immobilieninvestitionen – Gerade für Kleinanleger!",
                url: "/artikel/immobilieninvestitionen/",
            },
        ],
    },
    immobilienbewertung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Immobilienbewertung – Wie funktioniert das?",
                url: "/artikel/immobilienbewertung/",
            },
        ],
    },
    bausparforderung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Sicherheit in Krisenzeiten: Die Bausparförderung in Österreich",
                url: "/artikel/bausparforderung/",
            },
        ],
    },
    kreditregeln: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Neue Kreditregeln ab Juli 2022 – Was ändert sich?",
                url: "/artikel/kreditregeln/",
            },
        ],
    },
    bauenOderKaufen: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Soll ich mir mein Traumhaus selbst bauen oder kaufen?",
                url: "/artikel/bauen-oder-kaufen/",
            },
        ],
    },
    baukonto: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Alles Wichtige zum Thema Baukonto",
                url: "/artikel/baukonto/",
            },
        ],
    },
    darlehenUndKredit: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Unterschied zwischen Darlehen und Kredit",
                url: "/artikel/darlehen-und-kredit/",
            },
        ],
    },
    konditionen: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "So bekommst du die besten Konditionen",
                url: "/artikel/konditionen/",
            },
        ],
    },
    kreditvertrag: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Der Kreditvertrag – das musst du wissen",
                url: "/artikel/kreditvertrag/",
            },
        ],
    },
    einlagensicherung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Einlagensicherung – was ist das?",
                url: "/artikel/einlagensicherung/",
            },
        ],
    },
    bankgarantie: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Die Bankgarantie bei Immobilienkrediten – wozu ist sie gut?",
                url: "/artikel/bankgarantie/",
            },
        ],
    },
    umzug: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "10 Tipps vor dem Umzug",
                url: "/artikel/umzug/",
            },
        ],
    },
    zinscap: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Entwicklung und Prognose",
                url: "/artikel/zinscap/",
            },
        ],
    },
    annuitaetendarlehen: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Annuitätendarlehen – Tipps zur häufigsten Finanzierungsform der Immobilienwelt",
                url: "/artikel/annuitaetendarlehen/",
            },
        ],
    },
    notarkosten: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Alles Wissenswerte zu Notarkosten und wie man dabei sparen kann",
                url: "/artikel/notarkosten/",
            },
        ],
    },
    SchuldenErben: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Schulden erben – Die wichtigsten Antworten um vererbte Schulden.",
                url: "/artikel/schulden-erben/",
            },
        ],
    },
    abgeltungssteuer: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was du über die Abgeltungssteuer wissen solltest",
                url: "/artikel/abgeltungssteuer/",
            },
        ],
    },
    aufbewahrungspflicht: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Für welche Dokumente gilt nach dem Immobilienkauf eine Aufbewahrungspflicht?",
                url: "/artikel/aufbewahrungspflicht/",
            },
        ],
    },
    gebaudeversicherung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was deckt eine Gebäudeversicherung?",
                url: "/artikel/gebaudeversicherung/",
            },
        ],
    },
    grundsteuer: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Unterschied zwischen Grundsteuer und Grunderwerbssteuer",
                url: "/artikel/grundsteuer/",
            },
        ],
    },
    haushaltsversicherung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Haushaltsversicherung – Die Basics im Überblick",
                url: "/artikel/haushaltsversicherung/",
            },
        ],
    },
    euribor: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was ist eigentlich der EURIBOR?",
                url: "/artikel/euribor/",
            },
        ],
    },
    kaufOderMiete: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Kauf oder Miete? Die Grundsatzfrage",
                url: "/artikel/kauf-oder-miete/",
            },
        ],
    },
    agio: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was ist eigentlich das Agio?",
                url: "/artikel/agio/",
            },
        ],
    },
    kaufanbot: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was ist ein Kaufanbot und wann sollte ich es machen?",
                url: "/artikel/kaufanbot/",
            },
        ],
    },
    legitimation: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Legitimation bei Kreditverträgen – was ist das?",
                url: "/artikel/legitimation/",
            },
        ],
    },
    altbausanierung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Planst du eine Altbausanierung? Das solltest du vorher wissen",
                url: "/artikel/altbausanierung/",
            },
        ],
    },
    besichtigunstermin: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "10 Tipps für deinen Besichtigungstermin",
                url: "/artikel/besichtigunstermin/",
            },
        ],
    },
    buergschaft: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was du über die Bürgschaft beim Immobilienkauf wissen solltest",
                url: "/artikel/buergschaft/",
            },
        ],
    },
    kreditFruehzeitigZurueckzahlen: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Kredit frühzeitig zurückzahlen",
                url: "/artikel/kredit-fruehzeitig-zurueckzahlen/",
            },
        ],
    },
    fremdwaehrungskredite: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Sind Fremdwährungskredite noch eine Option?",
                url: "/artikel/fremdwaehrungskredite/",
            },
        ],
    },
    grundbuchauszug: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Infos zum Grundbuchauszug – Was du wissen solltest",
                url: "/artikel/grundbuchauszug/",
            },
        ],
    },
    tippsFurDenWohnungskauf: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "10 Tipps für den Wohnungskauf",
                url: "/artikel/10-tipps-fur-den-wohnungskauf/",
            },
        ],
    },
    haushaltsrechnungSchrittFuerSchritt: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Deine Haushaltsrechnung – Schritt für Schritt",
                url: "/artikel/haushaltsrechnung-schritt-fuer-schritt/",
            },
        ],
    },
    realkredit: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was ist eigentlich ein Realkredit?",
                url: "/artikel/realkredit/",
            },
        ],
    },
    grunderwerbsteuer: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Die Grunderwerbsteuer – was ist das und wie viel macht sie aus?",
                url: "/artikel/grunderwerbsteuer/",
            },
        ],
    },
    unsichereZeitenAuswirkungenAufDenHauskauf: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Unsichere Zeiten: Auswirkungen auf den Hauskauf",
                url: "/artikel/unsichere-zeiten-auswirkungen-auf-den-hauskauf/",
            },
        ],
    },
    umkehrhypothek: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Schon einmal von der Umkehrhypothek gehört? Hier erfährst du, worum es geht",
                url: "/artikel/umkehrhypothek/",
            },
        ],
    },
    kreditaufstockung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Wie funktioniert eine Kreditaufstockung?",
                url: "/artikel/kreditaufstockung/",
            },
        ],
    },
    wohnkreditZuZweit: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Wohnkredit zu zweit – Vor- und Nachteile",
                url: "/artikel/wohnkredit-zu-zweit/",
            },
        ],
    },
    immobilienKaufenUndVermieten: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Immobilien kaufen und vermieten – zahlt sich das aus?",
                url: "/artikel/immobilien-kaufen-und-vermieten/",
            },
        ],
    },
    einnahmenAusgabenRechnung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Wie mache ich eine Einnahmen-Ausgaben-Rechnung?",
                url: "/artikel/einnahmen-ausgaben-rechnung/",
            },
        ],
    },
    zinshaus: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Die wichtigsten Infos zum Thema Zinshaus",
                url: "/artikel/zinshaus/",
            },
        ],
    },
    bauzinsen: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Bauzinsen – Das Wichtigste auf einen Blick",
                url: "/artikel/bauzinsen/",
            },
        ],
    },
    umbau: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "10 Tipps für den Umbau",
                url: "/artikel/umbau/",
            },
        ],
    },
    deflation: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Deflation – Definition, Ursachen, Folgen",
                url: "/artikel/deflation/",
            },
        ],
    },
    zwischensfinanzierung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Zwischenfinanzierung – Ein Überblick",
                url: "/artikel/zwischensfinanzierung/",
            },
        ],
    },
    privatdarlehen: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Privatdarlehen – Die Vor- und Nachteile",
                url: "/artikel/privatdarlehen/",
            },
        ],
    },
    bearbeitungsgebuehr: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Die Bearbeitungsgebühr – ein Überblick über die Situation in Österreich",
                url: "/artikel/bearbeitungsgebuehr/",
            },
        ],
    },
    baunebenkosten: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Mit diesen Baunebenkosten musst du rechnen",
                url: "/artikel/baunebenkosten/",
            },
        ],
    },
    renovierung: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Renovierung – welche Finanzierungsmöglichkeiten gibt es?",
                url: "/artikel/renovierung/",
            },
        ],
    },
    partner: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Partner",
                url: "/partner/",
            },
        ],
    },
    tippgeber: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Tippgeber",
                url: "/tippgeber/",
            },
        ],
    },
    presse: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Presse",
                url: "/presse/",
            },
        ],
    },
    welcheNebenkostenBeimKredit: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Welche Nebenkosten entstehen bei einem Kredit?",
                url: "/gut-zu-wissen/welche-nebenkosten-entstehen-bei-einem-kredit/",
            },
        ],
    },
    wasBeiImmobilienfinanzierungBeachten: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was muss ich bei der Immobilienfinanzierung beachten?",
                url: "/gut-zu-wissen/was-muss-ich-bei-der-immobilienfinanzierung-beachten/",
            },
        ],
    },
    kaufOderMieteGrundsatzfrage: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Kauf oder Miete? Die Grundsatzfrage",
                url: "/gut-zu-wissen/kauf-oder-miete-grundsatzfrage/",
            },
        ],
    },
    wasSindZinsen: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was genau sind Zinsen eigentlich?",
                url: "/gut-zu-wissen/was-genau-sind-zinsen-eigentlich/",
            },
        ],
    },
    zinsModelle: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Welche Zinsmodelle habe ich zur Auswahl? Fixzins vs. variabler Zins",
                url: "/gut-zu-wissen/fixzins-vs-variabler-zins/",
            },
        ],
    },
    wieVielKredit: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Wie viel Kredit kann ich mir leisten?",
                url: "/gut-zu-wissen/wie-viel-kredit-kann-ich-mir-leisten/",
            },
        ],
    },
    eigenmittel: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Welche Möglichkeiten der Eigenmittel gibt es?",
                url: "/gut-zu-wissen/welche-moeglichkeiten-der-eigenmittel-gibt-es/",
            },
        ],
    },
    immobilienbewertungWieFunktioniertDas: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Immobilienbewertung - Wie funktioniert das?",
                url: "/gut-zu-wissen/immobilienbewertung-wie-funktioniert-das/",
            },
        ],
    },
    wasBekommtMiracl: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Was bekommt miracl für den Service?",
                url: "/gut-zu-wissen/was-bekommt-miracl-fuer-den-service/",
            },
        ],
    },
    AnnaUndPaul: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description:
                    "Auf der Suche nach einer Finanzierung für ein Haus am Land, das sie zum Wohnsitz ihrer gerade gegründeten Familie machen wollten, wurde der Kreditantrag eines jungen Paars von mehr als nur einer Bank abgelehnt. Erst miracl half ihnen, ein Kreditinstitut zu finden und ließ ihren Traum Wirklichkeit werden.",
                url: "/unsere-kunden/anna-und-paul/",
            },
        ],
    },
    Daniela: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description:
                    "Nach der Trennung von ihrem Partner will eine alleinerziehende Mutter den gemeinsamen Hauskredit auf sich alleine übertragen. Selbst ihre Hausbank lehnt ab. Miracl hält an einer winzigen Erfolgschance fest und verschafft ihr schließlich sogar niedrigere Raten.",
                url: "/unsere-kunden/daniela/",
            },
        ],
    },
    Beate: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description:
                    "Als sie gerade dabei sind, ein gemeinsamen Haus zu bauen, wird eine Selbstständige Ende 50 von ihrem Partner verlassen. Eine Bank für die weitere Finanzierung zu finden, scheint aussichtslos, doch mit miracl finder sich eine Lösung.",
                url: "/unsere-kunden/beate/",
            },
        ],
    },
    vertragsservice: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Vertragserrichtung. Zu Top-Konditionen.",
                url: "/vertragsservice/",
            },
        ],
    },
    artikel: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: "Ratgeber",
                url: "/ratgeber/",
            },
        ],
    },
    immokreditStudie202122: {
        path: [
            {
                description: "Home",
                url: "/",
            },
            {
                description: " Immokredit Studie 2021/22",
                url: "/artikel/immokredit-studie-2021-22/",
            },
        ],
    },
};

export default breadcrumbPagesWhiteList;
