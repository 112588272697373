import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const BreadcrumbsStructuredData = ({ siteUrl, breadcrumbEntries }) => {
    const breadcrumbsStructuredDataSchema = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [],
    };

    breadcrumbEntries.forEach((breadcrumbEntry, idx) => {
        breadcrumbsStructuredDataSchema.itemListElement.push({
            "@type": "ListItem",
            position: idx + 1,
            name: breadcrumbEntry.description,
            item: `${siteUrl}${breadcrumbEntry.url}`,
            itemType: "https://schema.org/WebPage",
        });
    });

    return (
        <BreadcrumbsStructuredDataWrapper>
            <Helmet>
                {Object.keys(breadcrumbsStructuredDataSchema).length && (
                    <script type="application/ld+json">{JSON.stringify(breadcrumbsStructuredDataSchema)}</script>
                )}
            </Helmet>
        </BreadcrumbsStructuredDataWrapper>
    );
};
const BreadcrumbsStructuredDataWrapper = styled.div``;

BreadcrumbsStructuredData.propTypes = {
    breadcrumbEntries: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default BreadcrumbsStructuredData;
