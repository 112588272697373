import React from "react";

import Breadcrumbs from "./Breadcrumbs";

import styled from "styled-components";

import { useStaticQuery, graphql } from "gatsby";

import breadcrumbPagesWhiteList from "./BreadcrumbWhiteList";

import BreadcrumbsStructuredData from "./BreadcrumbsStructuredData";

/**
 * This component can render a UI strip looking breadcrumb with proper html
 * structured breadcrumb data or shouldRenderStructuredData false render html component else
 * the structured lb+json data will be rendered, defaults to shouldRenderStructuredData false
 */
const BreadcrumbList = ({ page, shouldRenderStructuredData = true }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    if (breadcrumbPagesWhiteList[page]) {
        const { path } = breadcrumbPagesWhiteList[page];

        const shouldRenderComponent = !shouldRenderStructuredData;

        const siteUrl = data.site.siteMetadata.siteUrl || "https://miracl.at";

        return (
            <BreadcrumbListWrapper>
                {shouldRenderComponent && <Breadcrumbs breadcrumbEntries={path} siteUrl={siteUrl} />}

                {shouldRenderStructuredData && <BreadcrumbsStructuredData breadcrumbEntries={path} siteUrl={siteUrl} />}
            </BreadcrumbListWrapper>
        );
    }

    return null;
};

const BreadcrumbListWrapper = styled.div``;

export default BreadcrumbList;
